// Variables
@import 'variables';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Sunflower:wght@500&display=swap');


// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import 'landing_page';

/* NAVIGATION STYLE START*/


#menu-container {
    background: $primary-color;
}

.bg-primary-color {
    background: $primary-color;
}

.nav-link {
    font-size: 20px;
    color: $tertiary-color !important;
}

.gold-background {
    background: $tertiary-color;
}

#menu-img-logo {
    top: 10px !important;
    height: 100px !important;
    width: 100px;
    line-height: 150px;
    position: absolute;
}

/*JUMBOTRON START*/

#jumbotron_image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#jumbotron_text {
 display:none;
}

/*JUMBOTRON END*/

@include media-breakpoint-up(lg) {
    #jumbotron_text {
        display:block;
        border: 5px solid $tertiary-color;
        width: 50%;
        text-align: center;
        margin: -30px auto 0;
        position: relative;
        background: white;
        line-height: 60px;
    }

    #navigation {
        height: 100px;
    }
    .navbar-brand {
        width: 150px;
    }

        #logo-href {
            width: 150px;
            overflow-block: auto;
        }

        #menu-img-logo {
            top: 10px !important;
            height: 211px !important;
            width: 211px;
            line-height: 150px;
            position: absolute;
        }
}
/* NAVIGATION STYLE END*/

/*START CUSTOM STUFF*/
.with-border {
    border: 5px solid $tertiary-color;
}

.btn-border {
    border: 5px solid $tertiary-color;
    width: 100%;
    font-size:1rem;
    background: $primary-color;
    color:$secondary-color;
}

.btn-border:hover {
    background: $secondary-color;
    color:$primary-color;
}

.navigation-button {
    width: 80%;
    margin:0 auto;
    display:block;
    font-size:calc(1em + 1vw);
}

.nav-link:hover {
    font-weight: bold;
    border-bottom: 2px solid $tertiary-color;
}

.nav-link.active {
    font-weight: bold;
    border-bottom: 2px solid $tertiary-color;
}


/*END CUSTOM STUFF*/

/* UTILS*/
.text-justify {
    text-align: justify;
}

.min-height-100 {
    min-height: 100%;
}

.text-color-light {
    color: $secondary-color;
}
.word-break-break-all {
    word-break:break-all;
}

.text-color-tertiary {
    color:$tertiary-color;
}
/*END UTILS*/