// Body
$body-bg: #FFFFFFFF;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$primary-font-family:'Sunflower', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//colors
$primary-color:#181818;
$secondary-color:#FFFFFF;
$tertiary-color:#F6C225;
